import React, { useState } from 'react';
import { Typography, Grid, Box, Button } from '@mui/material';
import { Add as AddIcon, Computer as ComputerIcon, CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { ServerGrid } from '../dashboard/ChartGrid';
import Notifications from '../dashboard/Notifications';
import TrainingJobs from '../dashboard/TrainingJobs';

export default function Dashboard() {
  const [servers] = useState([]);
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [selectedMetric, setSelectedMetric] = useState('cpuUsage');
  const [selectedTimeframe, setSelectedTimeframe] = useState('hour');

  return (
    <Box sx={{ 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column',
      p: 3,
      overflow: 'auto'
    }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4">
          Dashboard
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => navigate('/training')}
          >
            New Training
          </Button>
          <Button
            variant="contained"
            startIcon={<ComputerIcon />}
            onClick={() => navigate('/discover-instances')}
          >
            Add Server
          </Button>
          <Button
            variant="contained"
            startIcon={<CloudUploadIcon />}
            onClick={() => navigate('/deployment')}
          >
            Deploy
          </Button>
        </Box>
      </Box>

      {/* Main Content */}
      <Box sx={{ 
        flexGrow: 1,
        display: 'flex',
        minHeight: 0
      }}>
        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
          {/* Left Column - Server Grid */}
          <Grid item xs={12} lg={8} sx={{ 
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <ServerGrid 
              selectedMetric={selectedMetric}
              selectedTimeframe={selectedTimeframe}
              setSelectedMetric={setSelectedMetric}
              setSelectedTimeframe={setSelectedTimeframe}
              servers={servers}
            />
          </Grid>

          {/* Right Column - Notifications and Training Jobs */}
          <Grid item xs={12} lg={4} sx={{ 
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Grid container spacing={2} sx={{ height: '100%' }}>
              {/* Notifications */}
              <Grid item xs={12} sx={{ height: '50%' }}>
                <Notifications />
              </Grid>

              {/* Training Jobs */}
              <Grid item xs={12} sx={{ height: '50%' }}>
                <TrainingJobs />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
