import React from 'react';
import { Card, CardHeader, CardContent, Avatar, List, ListItem, Box, Typography, Chip } from '@mui/material';
import { Psychology as PsychologyIcon } from '@mui/icons-material';
import StyledLinearProgress from '../StyledLinearProgress';

export const trainingJobs = [
  { id: 1, name: "Mistral-7B Fine-tuning", status: "In Progress", progress: 33 },
  { id: 2, name: "CodeLlama Python Fine-tuning", status: "In Progress", progress: 67 },
  { id: 3, name: "Llama-3.2-3B Fine-tuning", status: "Completed", progress: 100 }
];

export default function TrainingJobs() {
  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardHeader 
        title="Active Training Jobs"
        titleTypography={{ variant: 'h6' }}
        avatar={<Avatar sx={{ bgcolor: 'primary.main', width: 32, height: 32 }}><PsychologyIcon /></Avatar>}
        sx={{ pb: 1 }}
      />
      <CardContent sx={{ 
        flex: 1,
        overflowY: 'auto',
        p: 1,
        '&:last-child': { pb: 1 }
      }}>
        <List dense disablePadding>
          {trainingJobs.map((job) => (
            <ListItem 
              key={job.id} 
              sx={{ 
                bgcolor: 'background.paper',
                mb: 0.5,
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider'
              }}
            >
              <Box sx={{ width: '100%', p: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0.5 }}>
                  <Typography variant="subtitle2" sx={{ display: 'flex', alignItems: 'center' }}>
                    <PsychologyIcon sx={{ fontSize: 16, mr: 0.5 }} />
                    {job.name}
                  </Typography>
                  <Chip 
                    label={job.status} 
                    size="small" 
                    color={job.status === 'Running' ? 'success' : job.status === 'Queued' ? 'warning' : 'default'}
                  />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <StyledLinearProgress 
                    variant="determinate" 
                    value={job.progress} 
                    sx={{ flexGrow: 1, height: 6 }}
                  />
                  <Typography variant="caption" sx={{ minWidth: '45px' }}>
                    {job.progress}%
                  </Typography>
                </Box>
              </Box>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
