import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

export const KeycloakRoute = ({ children, requiredRoles = [] }) => {
  const { user, keycloak } = useAuth();

  if (!keycloak.authenticated) {
    return <Navigate to="/" replace />;
  }

  if (!user) {
    keycloak.logout();
    return <Navigate to="/" replace />;
  }

  return children;
};