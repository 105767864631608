import React from 'react';
import { Card, CardHeader, CardContent, Avatar, List, ListItem, ListItemAvatar, ListItemText, Chip } from '@mui/material';
import { Notifications as NotificationsIcon } from '@mui/icons-material';

export const notifications = [
  { id: 1, message: 'High CPU usage detected on Production Server 1', severity: 'warning' },
  { id: 2, message: 'GPU temperature exceeding threshold on Staging Server', severity: 'error' }
];

export default function Notifications() {
  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardHeader
        title="Notifications"
        titleTypography={{ variant: 'h6' }}
        avatar={<Avatar sx={{ bgcolor: 'secondary.main', width: 32, height: 32 }}><NotificationsIcon /></Avatar>}
        sx={{ pb: 1 }}
      />
      <CardContent sx={{ 
        flex: 1,
        overflowY: 'auto',
        p: 1,
        '&:last-child': { pb: 1 }
      }}>
        <List dense disablePadding>
          {notifications.map((notification) => (
            <ListItem key={notification.id} sx={{ py: 0.5 }}>
              <ListItemAvatar>
                <Avatar sx={{ 
                  bgcolor: notification.severity === 'error' ? 'error.main' : 'warning.main',
                  width: 32,
                  height: 32
                }}>
                  <NotificationsIcon fontSize="small" />
                </Avatar>
              </ListItemAvatar>
              <ListItemText 
                primary={notification.message}
                primaryTypography={{ variant: 'body2' }}
                secondary={
                  <Chip 
                    label={notification.severity} 
                    size="small" 
                    color={notification.severity === 'error' ? 'error' : 'warning'} 
                  />
                }
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
}
