import React, { useState } from 'react';
import { Box, TextField, Button, Typography, Paper, Grid, Alert, Collapse, IconButton } from '@mui/material';
import { useAuth } from '../authentication/AuthContext';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export default function Settings() {
  const { user, updateUser } = useAuth();
  const [profile, setProfile] = useState({
    name: user?.name || '',
    email: user?.email || '',
    apiKey: '',
    huggingfaceToken: user?.huggingfaceToken || '',
    kaggleToken: user?.kaggleToken || '',
    sshKeys: user?.sshKeys || [''],
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [showSshKeys, setShowSshKeys] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setLoading(true);

    try {
      // Add your profile update API call here
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      updateUser({
        name: profile.name,
        email: profile.email,
        huggingfaceToken: profile.huggingfaceToken,
        kaggleToken: profile.kaggleToken,
        sshKeys: profile.sshKeys,
        apiKey: profile.apiKey
      });
      
      setSuccess('Settings updated successfully');
    } catch (err) {
      setError('Failed to update settings');
    } finally {
      setLoading(false);
    }
  };

  const handleAddSshKey = () => {
    setProfile({
      ...profile,
      sshKeys: [...profile.sshKeys, '']
    });
  };

  const handleSshKeyChange = (index, value) => {
    const updatedSshKeys = [...profile.sshKeys];
    updatedSshKeys[index] = value;
    setProfile({
      ...profile,
      sshKeys: updatedSshKeys
    });
  };

  const handleRemoveSshKey = (index) => {
    const updatedSshKeys = profile.sshKeys.filter((_, i) => i !== index);
    setProfile({
      ...profile,
      sshKeys: updatedSshKeys
    });
  };

  return (
    <Box sx={{ maxWidth: "100%", mx: 'auto', p: 1 }}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>Settings</Typography>
        {error && <Alert severity="error" sx={{ mb: 1 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 1 }}>{success}</Alert>}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Full Name"
                value={profile.name}
                onChange={(e) => setProfile({...profile, name: e.target.value})}
              />
            </Grid>
            
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                value={profile.email}
                onChange={(e) => setProfile({...profile, email: e.target.value})}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="API Key"
                value={profile.apiKey}
                onChange={(e) => setProfile({...profile, apiKey: e.target.value})}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Hugging Face Token"
                value={profile.huggingfaceToken}
                onChange={(e) => setProfile({...profile, huggingfaceToken: e.target.value})}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Kaggle Token"
                value={profile.kaggleToken}
                onChange={(e) => setProfile({...profile, kaggleToken: e.target.value})}
              />
            </Grid>

            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="subtitle1">
                  SSH Keys ({profile.sshKeys.length})
                </Typography>
                <IconButton 
                  onClick={() => setShowSshKeys(!showSshKeys)}
                  size="small"
                >
                  {showSshKeys ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Box>
              
              <Collapse in={showSshKeys}>
                <Box sx={{ mt: 1 }}>
                  {profile.sshKeys.map((key, index) => (
                    <Box key={index} sx={{ display: 'flex', gap: 1, mb: 1 }}>
                      <TextField
                        fullWidth
                        multiline
                        rows={2}
                        label={`SSH Key ${index + 1}`}
                        value={key}
                        onChange={(e) => handleSshKeyChange(index, e.target.value)}
                      />
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => handleRemoveSshKey(index)}
                        disabled={profile.sshKeys.length === 1}
                        sx={{ minWidth: '80px' }}
                      >
                        Remove
                      </Button>
                    </Box>
                  ))}
                  <Button
                    variant="outlined"
                    onClick={handleAddSshKey}
                    sx={{ mb: 1 }}
                  >
                    Add SSH Key
                  </Button>
                </Box>
              </Collapse>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="subtitle1" gutterBottom sx={{ mt: 1 }}>
                Change Password
              </Typography>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Please enter your current password and the new password you wish to set.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Current Password
              </Typography>
              <TextField
                fullWidth
                type="password"
                value={profile.currentPassword}
                onChange={(e) => setProfile({...profile, currentPassword: e.target.value})}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                New Password
              </Typography>
              <TextField
                fullWidth
                type="password"
                value={profile.newPassword}
                onChange={(e) => setProfile({...profile, newPassword: e.target.value})}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Confirm New Password
              </Typography>
              <TextField
                fullWidth
                type="password"
                value={profile.confirmNewPassword}
                onChange={(e) => setProfile({...profile, confirmNewPassword: e.target.value})}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                sx={{ mt: 1 }}
              >
                {loading ? 'Saving Changes...' : 'Save Changes'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
}
