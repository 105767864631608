import React from 'react';
import { Box, Card, CardContent, Typography, Select, MenuItem, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, ResponsiveContainer } from 'recharts';

const GridCell = styled(Box)(({ theme, color }) => ({
  width: '100%',
  paddingBottom: '60%',
  backgroundColor: color,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  }
}));

const GridContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(9, 1fr)',
  gridTemplateRows: 'repeat(4, 1fr)',
  gap: theme.spacing(1),
  width: '100%',
  maxWidth: '950px',
}));

const ServerName = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  fontSize: '0.75rem',
  color: theme.palette.text.primary,
  textAlign: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '90%',
}));

export const metrics = [
  { value: 'cpuUsage', label: 'CPU Usage' },
  { value: 'gpuUsage', label: 'GPU Usage' },
  { value: 'temperature', label: 'Temperature' },
  { value: 'memoryUsage', label: 'Memory Usage' },
  { value: 'status', label: 'Status' },
  { value: 'nodeHealth', label: 'Node Health' }
];

export const timeframes = [
  { value: 'hour', label: 'Last Hour' },
  { value: 'day', label: 'Last Day' },
  { value: 'week', label: 'Last Week' }
];

export const healthScoreData = [
  { time: '00:00', score: 85 },
  { time: '04:00', score: 82 },
  { time: '08:00', score: 88 },
  { time: '12:00', score: 90 },
  { time: '16:00', score: 87 },
  { time: '20:00', score: 85 },
  { time: '24:00', score: 86 },
];
export const serverData = [
  { 
    id: 1, 
    name: "Server-01", 
    cpuUsage: 40, 
    gpuUsage: 20, 
    temperature: 50, 
    memoryUsage: 70, 
    status: "Active",
    location: "USA",
    nodeHealth: "Healthy",
    nodeType: "Application"
  },
  { 
    id: 2, 
    name: "Server-02", 
    cpuUsage: 80, 
    gpuUsage: 60, 
    temperature: 70, 
    memoryUsage: 50, 
    status: "Idle",
    location: "Germany", 
    nodeHealth: "Warning",
    nodeType: "Database"
  },
  { 
    id: 3, 
    name: "Server-03", 
    cpuUsage: 20, 
    gpuUsage: 30, 
    temperature: 40, 
    memoryUsage: 30, 
    status: "Active",
    location: "France",
    nodeHealth: "Healthy", 
    nodeType: "Application"
  },
  { 
    id: 4, 
    name: "Server-04", 
    cpuUsage: 10, 
    gpuUsage: 50, 
    temperature: 35, 
    memoryUsage: 20, 
    status: "Inactive",
    location: "UK",
    nodeHealth: "Critical",
    nodeType: "Storage"
  },
  { 
    id: 5, 
    name: "Server-05", 
    cpuUsage: 60, 
    gpuUsage: 40, 
    temperature: 60, 
    memoryUsage: 80, 
    status: "Idle",
    location: "Japan",
    nodeHealth: "Warning",
    nodeType: "Application"
  },
  {
    id: 6,
    name: "Server-06",
    cpuUsage: 45,
    gpuUsage: 35,
    temperature: 55,
    memoryUsage: 65,
    status: "Active", 
    location: "Canada",
    nodeHealth: "Healthy",
    nodeType: "Database"
  },
  {
    id: 7,
    name: "Server-07",
    cpuUsage: 75,
    gpuUsage: 85,
    temperature: 75,
    memoryUsage: 90,
    status: "Active",
    location: "Brazil",
    nodeHealth: "Warning",
    nodeType: "Application"
  },
  {
    id: 8,
    name: "Server-08",
    cpuUsage: 30,
    gpuUsage: 25,
    temperature: 45,
    memoryUsage: 40,
    status: "Idle",
    location: "Australia",
    nodeHealth: "Healthy",
    nodeType: "Storage"
  },
  {
    id: 9,
    name: "Server-09",
    cpuUsage: 90,
    gpuUsage: 95,
    temperature: 85,
    memoryUsage: 95,
    status: "Active",
    location: "India",
    nodeHealth: "Critical",
    nodeType: "Application"
  }
];

export const ServerGrid = ({ selectedMetric, selectedTimeframe, setSelectedMetric, setSelectedTimeframe }) => {
  const getColor = (value, metric) => {
    if (value === undefined) return '#e0e0e0';
    if (metric === 'status') {
      return value === 'Active' ? '#4caf50' : 
             value === 'Idle' ? '#ff9800' : '#f44336';
    }
    if (metric === 'nodeHealth') {
      return value === 'Healthy' ? '#4caf50' : 
             value === 'Warning' ? '#ff9800' : '#f44336';
    }
    return value <= 30 ? '#4caf50' :
           value <= 70 ? '#ff9800' : '#f44336';
  };

  const getValue = (server, metric) => {
    if (!server) return undefined;
    return server[metric];
  };

  const getTooltipContent = (server) => {
    if (!server) return 'Empty slot';
    return (
      <Box sx={{ p: 1 }}>
        <Typography variant="subtitle2">{server.name}</Typography>
        <Typography variant="body2">Status: {server.status}</Typography>
        <Typography variant="body2">Location: {server.location}</Typography>
        <Typography variant="body2">Node Type: {server.nodeType}</Typography>
        <Typography variant="body2">Node Health: {server.nodeHealth}</Typography>
        <Typography variant="body2">CPU: {server.cpuUsage}%</Typography>
        <Typography variant="body2">GPU: {server.gpuUsage}%</Typography>
        <Typography variant="body2">Memory: {server.memoryUsage}%</Typography>
        <Typography variant="body2">Temperature: {server.temperature}°C</Typography>
      </Box>
    );
  };

  const getColorBarLabels = (metric) => {
    if (metric === 'status') {
      return ['Active', 'Idle', 'Inactive'];
    }
    if (metric === 'nodeHealth') {
      return ['Healthy', 'Warning', 'Critical'];
    }
    return ['100%', '70%', '30%', '0%'];
  };

  return (
    <Card sx={{ width: '100%' }}>
      <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2
        }}>
          <Typography variant="h6">Global Server Health Score</Typography>
          <Select
            size="small"
            value={selectedTimeframe}
            onChange={(e) => setSelectedTimeframe(e.target.value)}
            sx={{ minWidth: 120 }}
          >
            {timeframes.map((timeframe) => (
              <MenuItem key={timeframe.value} value={timeframe.value}>
                {timeframe.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
        
        <Box sx={{ height: 350, mb: 4 }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart 
              data={healthScoreData}
              margin={{ top: 5, right: 5, bottom: 5, left: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis 
                dataKey="time"
                axisLine={{ stroke: '#E0E0E0' }}
                tickLine={{ stroke: '#E0E0E0' }}
              />
              <YAxis 
                domain={[0, 100]}
                axisLine={{ stroke: '#E0E0E0' }}
                tickLine={{ stroke: '#E0E0E0' }}
              />
              <RechartsTooltip />
              <Line
                type="monotone"
                dataKey="score"
                stroke="#8884d8"
                strokeWidth={2}
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>

        <Box sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2
        }}>
          <Typography variant="h6">Server Status Overview</Typography>
          <Select
            size="small"
            value={selectedMetric}
            onChange={(e) => setSelectedMetric(e.target.value)}
            sx={{ minWidth: 120 }}
          >
            {metrics.map((metric) => (
              <MenuItem key={metric.value} value={metric.value}>
                {metric.label}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box sx={{ 
          display: 'flex', 
          gap: 2,
          width: '100%',
          alignItems: 'stretch'
        }}>
          <GridContainer>
            {Array(36).fill(null).map((_, index) => {
              const server = serverData[index] || null;
              const value = getValue(server, selectedMetric);
              return (
                <Tooltip 
                  key={index}
                  title={getTooltipContent(server)}
                  arrow
                  placement="top"
                >
                  <Box sx={{ position: 'relative' }}>
                    <GridCell 
                      color={getColor(value, selectedMetric)}
                    />
                    {server && (
                      <ServerName>
                        {server.name}
                      </ServerName>
                    )}
                  </Box>
                </Tooltip>
              );
            })}
          </GridContainer>

          <Box sx={{ 
            width: 50, 
            display: 'flex', 
            flexDirection: 'column', 
            position: 'relative',
            mt: 1,
            mb: 1
          }}>
            <Typography variant="caption" sx={{ textAlign: 'center', mb: 1 }}>High</Typography>
            <Box sx={{ 
              flex: 1,
              background: 'linear-gradient(to bottom, #f44336, #ff9800, #4caf50)',
              borderRadius: 1,
              my: 1,
              position: 'relative'
            }}>
              {getColorBarLabels(selectedMetric).map((label, index, array) => (
                <Typography
                  key={label}
                  variant="caption"
                  sx={{
                    position: 'absolute',
                    right: -5,
                    transform: 'translateX(100%)',
                    color: 'text.secondary',
                    fontSize: '0.7rem',
                    ...(index === 0 ? {
                      top: '0%',
                    } : index === array.length - 1 ? {
                      bottom: '0%',
                    } : {
                      top: `${(index / (array.length - 1)) * 100}%`,
                      transform: 'translateX(100%) translateY(-50%)',
                    })
                  }}
                >
                  {label}
                </Typography>
              ))}
            </Box>
            <Typography variant="caption" sx={{ textAlign: 'center', mt: 1 }}>Low</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
